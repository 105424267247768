<template>
    <div id="app">
        <!-- This example requires Tailwind CSS v2.0+ -->
        <router-view></router-view>
        <div class="fixed bottom-0 right-0 pr-8 pb-8">
            <button type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="-ml-1 mr-2 h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                New
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            progress: 0,
            error: false,
        };
    },
    methods: {
        errorDone() {
            this.error = true;
        },
        progressDone() {
            this.progress = 0;
        },
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            console.log('beforeEach', to, from, next);
            // Start the loading bar before the route change
            this.progress = 0;
            this.error = false;
            const interval = setInterval(() => {
                this.progress += 5;
                console.log('progress', this.progress);
                if (this.progress >= 90) {
                    clearInterval(interval);
                }
            }, 100);
            next();
        });

        this.$router.afterEach((to, from) => {
            console.log('afterEach', to, from);
        });
    },

}
</script>

<style></style>