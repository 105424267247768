import Vue from 'vue'
import App from './App.vue'
import VTooltip from 'v-tooltip'
import Router from "vue-router"

import './assets/main.css'

import home from "./components/home.vue"
import write from "./components/write.vue"
import landing from "./components/landing.vue"
import calendar from "./components/calendar.vue"
import memories from "./components/memories.vue"
import timeline from "./components/timeline.vue"

import firebase from "firebase"

const firebaseConfig = {
    apiKey: "AIzaSyDJgkbxY-2rLEk8vnNvRgX0Yl5vOgD7E50",
    authDomain: "life-in-weeks-b37fb.firebaseapp.com",
    projectId: "life-in-weeks-b37fb",
    storageBucket: "life-in-weeks-b37fb.appspot.com",
    messagingSenderId: "885317737991",
    appId: "1:885317737991:web:0b452ccafb8a79789294a2",
    measurementId: "G-4F332K4LZF"
};
firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore()
export const firestore = firebase.firestore
export const auth = firebase.auth
export const storage = firebase.storage()

Vue.config.productionTip = false
Vue.use(VTooltip, {
    defaultPlacement: 'auto',
    defaultPopperOptions: {
        modifiers: {
            preventOverflow: {
                enabled: true
            }
        }
    }

})
Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            name: "home",
            component: home,
        },
        {
            path: "/write/:id",
            name: "write",
            component: write,
        },
        {
            path: "/calendar",
            name: "calendar",
            component: calendar,
        },
        {
            path: "/memories",
            name: "memories",
            component: memories,
        },
        {
            path: "/timeline",
            name: "timeline",
            component: timeline,
        },
        {
            path: "/start-a-journal",
            name: "landing",
            component: landing,
        },
    ]
});


let app
auth().onAuthStateChanged(() => {
    if (!app) {
        app = new Vue({
            router,
            render: h => h(App),
        }).$mount('#app')
    }
});

router.afterEach((to, from) => {
    console.log(to, from);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
});