<template>
    <div class="p-6">
        <div class="titleheader bg-white px-4 pb-5 border-b border-gray-200 sm:px-6">
            <div class="grid grid-cols-2">
                <div class="text-left">
                    <button @click="$router.push('/')" class="inline-flex space-x-4 pb-5">
                        <span class="inline-flex items-center text-sm font-medium text-rose-500 space-x-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                            </svg>
                            <span>Go back</span>
                        </span>
                    </button>
                </div>
                <div class="text-right">
                    <span v-if="!saved"
                        class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-rose-100 text-rose-500">
                        <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-rose-600" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx="4" cy="4" r="3" />
                        </svg>
                        Saving...
                    </span>
                    <span v-else
                        class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-500">
                        <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-green-600" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx="4" cy="4" r="3" />
                        </svg>
                        Saved
                    </span>
                </div>
                <div class="pt-1 col-span-2">
                    <input class="text-2xl leading-6 font-black text-black-900 w-full"
                        style="outline: 0px solid transparent;" v-model="title" @input="titleUpdated"
                        :placeholder="'Week No. ' + $route.params.id + ' Log'">
                </div>
            </div>
            <div class="pt-4">
                <span
                    class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-rose-100 text-rose-700">
                    Small
                    <button type="button"
                        class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-rose-400 hover:bg-rose-200 hover:text-rose-500 focus:outline-none focus:bg-rose-500 focus:text-white">
                        <span class="sr-only">Remove small option</span>
                        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>
            </div>
        </div>
        <div id="editorjs"></div>
        <hr class="pb-7">
        <div class="titleheader bg-white px-4 py-5 border-b border-gray-200 sm:px-6 grid grid-cols-3">
            <!-- Add grid utilities here -->
            <div class="col-span-2 text-left"> <!-- Align to the left -->
                <h2 class="text-2xl leading-6 font-black text-black-900 w-full" style="outline: 0px solid transparent;">
                    Memories</h2>
            </div>
            <div class="text-right"> <!-- Align to the right -->
                <button @click="$router.push('/')" class="inline-flex space-x-4 pb-5">
                    <span class="inline-flex items-center text-sm font-medium text-rose-500 space-x-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                        </svg>
                        <span>Upload</span>
                    </span>
                </button>
            </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 grid-auto-rows min-auto">
            <div>
                <img src="https://wsrv.nl/?w=100&h=100&fit=cover&url=https://firebasestorage.googleapis.com/v0/b/life-in-weeks-b37fb.appspot.com/o/images%252Fb6933d59-b3f5-41b9-8499-328e6e918adb.jpg?alt=media&token=1b692df1-3b79-463c-ab5b-615350aa09cf"
                    alt="Image 1" class="w-full object-cover rounded-xl">
            </div>
            <div>
                <img src="https://firebasestorage.googleapis.com/v0/b/life-in-weeks-b37fb.appspot.com/o/images%2Fb6933d59-b3f5-41b9-8499-328e6e918adb.jpg?alt=media&token=1b692df1-3b79-463c-ab5b-615350aa09cf"
                    alt="Image 2" class="w-full object-cover rounded-xl">
            </div>
            <div>
                <img src="https://firebasestorage.googleapis.com/v0/b/life-in-weeks-b37fb.appspot.com/o/images%2F93fe4cb5-2d56-48df-8a41-3851f39bbb5e.jpg?alt=media&token=fe9d6617-c34b-444b-a860-640ff9406dce"
                    alt="Image 3" class="w-full object-cover rounded-xl">
            </div>
            <div>
                <img src="https://firebasestorage.googleapis.com/v0/b/life-in-weeks-b37fb.appspot.com/o/images%2F93fe4cb5-2d56-48df-8a41-3851f39bbb5e.jpg?alt=media&token=fe9d6617-c34b-444b-a860-640ff9406dce"
                    alt="Image 4" class="w-full object-cover rounded-xl">
            </div>
            <div>
                <img src="https://firebasestorage.googleapis.com/v0/b/life-in-weeks-b37fb.appspot.com/o/images%2Fedbc60ac-5c80-461e-b9d5-229a54d08a51.jpg?alt=media&token=75cbb414-d231-43c9-8df3-e0c6c64d0105"
                    alt="Image 1" class="w-full object-cover rounded-xl">
            </div>
            <div>
                <img src="https://firebasestorage.googleapis.com/v0/b/life-in-weeks-b37fb.appspot.com/o/images%2FIMG_5675.jpg?alt=media&token=78529940-bead-4c2f-8b2d-8fa359b9d677"
                    alt="Image 2" class="w-full object-cover rounded-xl">
            </div>
            <div>
                <img src="https://firebasestorage.googleapis.com/v0/b/life-in-weeks-b37fb.appspot.com/o/images%2F93fe4cb5-2d56-48df-8a41-3851f39bbb5e.jpg?alt=media&token=fe9d6617-c34b-444b-a860-640ff9406dce"
                    alt="Image 3" class="w-full object-cover rounded-xl">
            </div>
            <div>
                <img src="https://firebasestorage.googleapis.com/v0/b/life-in-weeks-b37fb.appspot.com/o/images%2F93fe4cb5-2d56-48df-8a41-3851f39bbb5e.jpg?alt=media&token=fe9d6617-c34b-444b-a860-640ff9406dce"
                    alt="Image 4" class="w-full object-cover rounded-xl">
            </div>
        </div>
        <div id="g_id_onload" data-client_id="885317737991-reij5fl9sbsu0rcdoq9habo5jdb7rpf6.apps.googleusercontent.com"
            data-context="signin" data-ux_mode="popup" data-callback="loggedIn" data-auto_prompt="false">
        </div>

        <div class="g_id_signin" data-type="standard" data-shape="rectangular" data-theme="outline" data-text="signin_with"
            data-size="large" data-logo_alignment="left">
        </div>
    </div>
</template>
<script>
import { auth } from '@/main.js'
import { db } from '@/main.js'

import gapi from 'gapi-client';

import EditorJS from '@editorjs/editorjs'
import Header from 'editorjs-header-with-alignment'
import Paragraph from 'editorjs-paragraph-with-alignment'
import Quote from '@editorjs/quote'
import Warning from '@editorjs/warning'
import Checklist from '@editorjs/checklist'
import NestedList from '@editorjs/nested-list'
import Image from '@editorjs/simple-image'
import Delimiter from '@editorjs/delimiter'
// import LinkTool from '@editorjs/link'

export default {
    name: 'write',
    data() {
        return {
            title: "",
            config: {
                holder: 'editorjs',
                inlineToolbar: ['link', 'marker', 'bold', 'italic'],
                hideToolbar: false,
                tools: {
                    header: Header,
                    paragraph: Paragraph,
                    quote: Quote,
                    warning: Warning,
                    checklist: Checklist,
                    nestedList: NestedList,
                    image: Image,
                    delimiter: Delimiter,
                    // linkTool: {
                    //     class: LinkTool,
                    //     config: {
                    //         headers: { "X-API-Key": "51265cba-b857-4817-b994-bf7da2acac5a" },
                    //         endpoint: 'https://api.peekalink.io/',
                    //     }
                    // }
                },
                data: {},
                autofocus: false,
                placeholder: 'Write about your week...',
                onChange: () => { this.save() }
            },
            editor: {},
            saved: true,
            isLoading: true,
            memories: [],
            //color coding
            newTagName: 'Default',
            newTagColor: '#000000',
            selectedTag: { name: 'Default', color: '#000000' },
            tags: [{ name: 'Default', color: '#000000' }],
        }
    },
    methods: {
        save() {
            this.saved = false;
            this.editor.save().then((outputData) => {
                if (outputData.blocks.length > 0) {
                    console.log('Article data: ', outputData);
                    db.collection("users").doc(auth().currentUser.uid).collection("weeks").doc(this.$route.params.id).set({
                        content: outputData,
                        title: this.title,
                    }, { merge: true }).then(() => {
                        this.saved = true;
                    })
                } else this.saved = true;
            }).catch((error) => {
                console.log('Saving failed: ', error)
            });
        },
        titleUpdated() {
            if (this.title != "" && this.title != null && this.title != undefined) {
                this.saved = false;
                db.collection("users").doc(auth().currentUser.uid).collection("weeks").doc(this.$route.params.id).set({
                    title: this.title,
                }, { merge: true }).then(() => {
                    this.saved = true;
                });
            }
        },
        initClient() {
            console.log('initClient', gapi.client);
            //secret: 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
            gapi.client.init({
                apiKey: 'AIzaSyDJgkbxY-2rLEk8vnNvRgX0Yl5vOgD7E50',
                clientId: '885317737991-reij5fl9sbsu0rcdoq9habo5jdb7rpf6.apps.googleusercontent.com',
                discoveryDocs: ['https://photoslibrary.googleapis.com/$discovery/rest'],
                scope: 'https://www.googleapis.com/auth/photoslibrary.readonly'
            }).then(() => {
                console.log(gapi);
            });
        },
        handleCredentialResponse(response) {
            console.log(response);
            // Handle the response here
        },
        addTag() {
            const newTag = {
                name: this.newTagName,
                color: this.newTagColor,
            };
            this.tags.push(newTag);
            this.newTagName = '';
            this.newTagColor = '#000000';
        },
        selectTag(tag) {
            this.selectedTag = tag;
        },
    },
    beforeMount() {
        if (!auth().currentUser) this.$router.push('/start-a-journal');
        else {
            db.collection("users").doc(auth().currentUser.uid).collection("weeks").doc(this.$route.params.id).get().then((doc) => {
                if (doc.exists) {
                    this.config.data = doc.data().content;
                    this.title = doc.data().title;
                    this.editor = new EditorJS(this.config);
                } else this.editor = new EditorJS(this.config);
            });
        }
    },
    mounted() {
        // gapi.load('client:auth2', this.initClient);
        window.handleCredentialResponse = this.handleCredentialResponse;
    }
}
</script>
<style>
.titleheader {
    max-width: 750px;
    margin: auto;
    margin-bottom: 40px;
}

#editorjs h1 {
    font-weight: 700;
    font-size: 1.9rem;
}

#editorjs h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    font-size: 1.7rem;
}

#editorjs h3 {
    font-size: 1.5rem;
}

#editorjs h4 {
    font-size: 1.35rem;
}

#editorjs h5 {
    font-size: 1.2rem;
}

#editorjs h6 {
    font-size: 1.1rem;
}

.gallery-container {
    overflow: auto;
    width: 100%;
    margin: 20px 0;
}

.gallery {
    white-space: nowrap;
    width: 100%;
}

.gallery img {
    width: 300px;
    height: auto;
    margin-right: 10px;
    padding: 8px 0;
    border-radius: 15px;
}

.codex-editor__redactor {
    padding-bottom: 100px !important;
}</style>