<template>
    <main>
        <Navigation></Navigation>
        <div class="flex flex-col items-center justify-center">
            <div class="container mx-auto p-6">
                <h1 class="text-4xl font-extrabold text-gray-800 pb-4">Memories</h1>
                <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                    <!-- Image 1 -->
                    <div class="group relative">
                        <img src="https://via.placeholder.com/500" alt="Placeholder" class="w-full h-auto rounded-lg">
                        <div class="overlay absolute inset-0 flex justify-center items-center bg-transparent rounded-lg">
                            <button class="overlay-button">View</button>
                        </div>
                    </div>
                    <!-- Image 2 -->
                    <div class="group relative">
                        <img src="https://via.placeholder.com/500" alt="Placeholder" class="w-full h-auto rounded-lg">
                        <div class="overlay absolute inset-0 flex justify-center items-center bg-transparent rounded-lg">
                            <button class="overlay-button">View</button>
                        </div>
                    </div>
                    <!-- Image 3 -->
                    <div class="group relative">
                        <img src="https://via.placeholder.com/500" alt="Placeholder" class="w-full h-auto rounded-lg">
                        <div class="overlay absolute inset-0 flex justify-center items-center bg-transparent rounded-lg">
                            <button class="overlay-button">View</button>
                        </div>
                    </div>
                    <!-- Repeat for more images -->
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Navigation from './navigation.vue';

export default {
    name: 'memories',
    components: { Navigation }
}
</script>

<style scoped>
/* Add your component styles here */
</style>
