<template>
    <main>
        <Navigation></Navigation>
        <!-- weeks-in-life -->
        <div class="pt-4 pb-32 px-8">
            <div class="sticky left-0 top-0 w-full py-1 z-10">
                <div class="bg-white my-5 px-2 py-5 rounded-xl w-full shadow">
                    <div class="-ml-4 -mt-2 flex items-center justify-between flex-nowrap">
                        <!-- Add your image here -->
                        <div class="ml-8 mt-2">
                            <h3 class="text-sm md:text-lg leading-6 font-black text-gray-900 text-center">
                                <span>Your Life<br>in Weeks</span>
                            </h3>
                        </div>
                        <div class="flex flex-grow justify-between mb-1 px-4">
                            <div class="w-full">
                                <span class="text-sm font-medium text-rose-500">
                                    <a class="underline"><b>{{ addCommatoNumber(current) }}</b></a> / <b>{{
                                        addCommatoNumber(total) }}</b>
                                </span>
                                <div class="w-full bg-gray-100 rounded-full h-2.5">
                                    <div class="bg-rose-500 h-2 rounded-full"
                                        :style="{ width: String(Math.round((current / total) * 100)) + '%' }">
                                    </div>
                                </div>
                                <span class="hidden md:block text-xs md:text-sm font-medium text-rose-500">
                                    {{ Math.round((current / total) * 100) }}% weeks elapsed - <a
                                        class="underline"><b>Scroll to current week</b></a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2 grid grid-cols text-sm">
                <div class="py-2 hidden">
                    <button type="button"
                        class="mx-auto flex h-8 w-8 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200">
                        <time datetime="2021-12-27">27</time>
                    </button>
                </div>
                <div class="py-2 weeks" v-for="week in weeks" :key="week.count">
                    <button v-tooltip="week.title ? week.title : 'Week ' + addCommatoNumber(week.count)" type="button"
                        class="mx-auto flex h-5 w-5 items-center justify-center rounded-md"
                        :class="{ 'bg-gray-350 text-gray-900 hover:bg-gray-600 border-2 border-gray-400': week.count < current, 'bg-rose-600 hover:bg-rose-800 font-semibold text-white blink border-2 border-rose-900': week.count == current, 'bg-gray-200': week.count > current, 'bg-rose-400 border-rose-600': history.includes(week.count) }"
                        :disabled="week.count > current" :ref="'week' + week.count"
                        @click="$router.push('/write/' + week.count)">
                    </button>
                </div>
            </div>
            <!-- /weeks-in-life -->
            <!-- birthday-modal -->
            <div v-if="modal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
                aria-modal="true">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div
                        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div class="sm:flex sm:items-start">
                            <div
                                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" class="h-6 w-6 text-red-600">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z" />
                                </svg>
                            </div>
                            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                    Pick your birthday
                                </h3>
                                <div class="mt-2">
                                    <input type="date" name="begin" placeholder="yyyy-mm-dd" value="2001-09-19"
                                        min="1997-01-01" :max="new Date().toISOString().split('T')[0]" v-model="birth"
                                        class="text-3xl font-extrabold">
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                            <button type="button" @click="birthdayUpdate"
                                class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm">
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /birthday-modal -->
        </div>
    </main>
</template>
<script>
import { auth } from "@/main.js";
import { db } from "@/main.js";
import Navigation from "./navigation.vue";

export default {
    name: 'home',
    data() {
        return {
            userExists: false,
            displayName: "",
            birth: '2000-01-01',
            birthday: new Date('2001-09-19'),
            death: new Date(),
            today: new Date(),
            current: 0,
            total: 0,
            weeks: [
                {
                    count: 0,
                    title: "",
                    special: false
                }
            ],
            history: [],
            memories: [],
            modal: false
        };
    },
    methods: {
        birthdayUpdate() {
            console.log(this.birth);
            this.fetchWeeks();
            this.modal = false;
            if (auth().currentUser) {
                db.collection("users").doc(auth().currentUser.uid).set({ birthday: this.birth }, { merge: true });
            }
        },
        getWeeksDiff(startDate, endDate) {
            const msInWeek = 1000 * 60 * 60 * 24 * 7;
            return Math.round(Math.abs(endDate - startDate) / msInWeek);
        },
        fetchWeeks() {
            this.death = new Date(this.birth);
            this.death.setFullYear(this.death.getFullYear() + 90);
            this.birthday = new Date(this.birth);
            console.log(this.birthday, this.today, this.death);
            this.current = this.getWeeksDiff(this.birthday, this.today);
            this.total = this.getWeeksDiff(this.birthday, this.death);
            //
            this.weeks = [];
            for (let index = 1; index < this.total; index++) {
                this.weeks.push({
                    count: index,
                    title: "",
                    special: false
                });
            }
        },
        logout() {
            auth().signOut().then(() => {
                this.$router.push('/start-a-journal');
            });
        },
        addBreakTag(str) {
            const words = str.trim().split(' ').filter(word => word !== '');
            if (words.length === 1) {
                return words[0];
            }
            const middleIndex = Math.floor(words.length / 2);
            words.splice(middleIndex, 0, '<br>');
            return words.join(' ');
        },
        addCommatoNumber(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    beforeMount() {
        //update auth displayname
        this.fetchWeeks();
        if (auth().currentUser) {
            this.userExists = true;
            this.displayName = auth().currentUser.displayName;
            db.collection("users").doc(auth().currentUser.uid).get().then((userData) => {
                if (userData.data().birthday) {
                    this.birth = userData.data().birthday;
                    this.fetchWeeks();
                }
                else
                    this.modal = true;
            });
        }
        else {
            setTimeout(() => {
                this.modal = true;
            }, 3000);
        }
        db.collection("users").doc(auth().currentUser.uid).collection("weeks").get().then((weeks) => {
            weeks.forEach((week) => {
                this.history.push(week.id);
                this.weeks[week.id - 1].title = week.data().title;
                this.weeks[week.id - 1].count = week.id;
                console.log(week.id, week.data());
            });
        });
    },
    mounted() {
        setTimeout(() => {
            this.$refs['week' + this.current][0].scrollIntoView({
                behaviour: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }, 1200);
    },
    components: { Navigation }
}
</script>

<style>
.bg-gray-350 {
    background: #c1c3c9 !important;
}

.weeks .bg-rose-400 {
    background: #fb7185 !important;
}

.blink {
    -webkit-animation: 1s blink ease infinite;
    -moz-animation: 1s blink ease infinite;
    -ms-animation: 1s blink ease infinite;
    -o-animation: 1s blink ease infinite;
    animation: 1s blink ease infinite;
}

@keyframes blink {

    from,
    to {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }
}

@-moz-keyframes blink {

    from,
    to {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }
}

@-webkit-keyframes blink {

    from,
    to {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }
}

@-ms-keyframes blink {

    from,
    to {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }
}

@-o-keyframes blink {

    from,
    to {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }
}

.tooltip {
    background: grey;
    color: #fff;
    padding: 2.5px 10px;
    font-size: 0.85rem;
    margin-bottom: 10px;
    border-radius: 7px;
}

.grid-cols {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

@media only screen and (min-width: 450px) {
    .grid-cols {
        grid-template-columns: repeat(16, minmax(0, 1fr));
    }
}

@media only screen and (min-width: 600px) {
    .grid-cols {
        grid-template-columns: repeat(22, minmax(0, 1fr));
    }
}

@media only screen and (min-width: 700px) {
    .grid-cols {
        grid-template-columns: repeat(26, minmax(0, 1fr));
    }
}

@media only screen and (min-width: 800px) {
    .grid-cols {
        grid-template-columns: repeat(21, minmax(0, 1fr));
    }
}

@media only screen and (min-width: 900px) {
    .grid-cols {
        grid-template-columns: repeat(26, minmax(0, 1fr));
    }
}

@media only screen and (min-width: 1000px) {
    .grid-cols {
        grid-template-columns: repeat(32, minmax(0, 1fr));
    }
}

@media only screen and (min-width: 1200px) {
    .grid-cols {
        grid-template-columns: repeat(38, minmax(0, 1fr));
    }
}

.logo {
    border-radius: 10px;
    width: 50px;
}

.shadow {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
</style>
