<template>
    <div class="bg-white">
        <main class="pb-20">
            <!-- Hero section -->
            <div class="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
                <div
                    class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
                    <div>
                        <div class="hidden">
                            <img class="h-11 w-auto"
                                src="https://tailwindui.com/img/logos/workflow-mark.svg?color=rose&shade=500"
                                alt="Workflow">
                        </div>
                        <div class="mt-20">
                            <div>
                                <button @click="$router.push('/')" class="inline-flex space-x-4">
                                    <span class="inline-flex items-center text-sm font-medium text-rose-500 space-x-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                        </svg>
                                        <span>Go back to calendar</span>
                                    </span>
                                </button>
                            </div>
                            <div class="mt-6 sm:max-w-xl">
                                <h1 class="text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">
                                    Start writing your life journal, <u class="font-extrabold">once a week</u>
                                </h1>
                                <p class="mt-6 text-xl text-gray-500">
                                    Write a letter to yourself about everything that impacted your life. You'll feel
                                    better about yourself looking back at learnings & accomplishments.
                                </p>
                            </div>
                            <div v-if="!userExists" class="mt-12 sm:max-w-lg sm:w-full sm:flex">
                                <button @click="signin"
                                    class="block w-full rounded-md border border-transparent px-5 py-3 bg-rose-500 text-base font-medium text-white shadow hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 sm:px-10">
                                    Continue with Google - it's free
                                </button>
                            </div>
                            <div class="mt-6">
                                <div class="inline-flex items-center divide-x divide-gray-300">
                                    <div class="flex-shrink-0 flex pr-5">
                                        <!-- Heroicon name: solid/star -->
                                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                        </svg>
                                        <!-- Heroicon name: solid/star -->
                                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                        </svg>
                                        <!-- Heroicon name: solid/star -->
                                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                        </svg>
                                        <!-- Heroicon name: solid/star -->
                                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                        </svg>
                                        <!-- Heroicon name: solid/star -->
                                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                        </svg>
                                    </div>
                                    <div class="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3"><span
                                            class="font-medium text-gray-900">Rated 5 stars</span> by over <span
                                            class="font-medium text-rose-500">90% of our beta users</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sm:mx-auto sm:max-w-3xl sm:px-6">
                    <div class="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                        <div class="hidden sm:block">
                            <div
                                class="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full">
                            </div>
                            <svg class="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0" width="404" height="392"
                                fill="none" viewBox="0 0 404 392">
                                <defs>
                                    <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                                        patternUnits="userSpaceOnUse">
                                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                            </svg>
                        </div>
                        <div class="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                            <img class="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                                src="~@/assets/ss.gif" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="pb-20 relative">
                <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                    <div class="relative sm:py-16 lg:py-0">
                        <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                            <div class="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
                            <svg class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404"
                                height="392" fill="none" viewBox="0 0 404 392">
                                <defs>
                                    <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20"
                                        patternUnits="userSpaceOnUse">
                                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                            </svg>
                        </div>
                        <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                            <div class=" overflow-hidden max-w-full w-full aspect-w-16 aspect-h-9">
                                <iframe style="height: 300px;width:100%" class="m-auto"
                                    src="https://www.youtube.com/embed/JXeJANDKwDc" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                    <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                        <div class="pt-12 sm:pt-16 lg:pt-20">
                            <h2 class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                                Build a fruitful habit
                            </h2>
                            <div class="mt-6 text-gray-500 space-y-6">
                                <p class="text-lg">
                                    <b>Lifeinweeks</b> help you write about everything you did each week. When you look
                                    back on this with all of your accomplishments, it will give you confidence and help
                                    you feel better about yourself. You'll be able to see how much progress you've made.
                                </p>
                            </div>
                        </div>
                        <div class="mt-10">
                            <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
                                <div class="border-t-2 border-gray-100 pt-6">
                                    <dd class="text-xl font-bold tracking-tight text-gray-700">
                                        Manages
                                        <br>
                                        Anxiety
                                    </dd>
                                </div>

                                <div class="border-t-2 border-gray-100 pt-6">
                                    <dd class="text-xl font-bold tracking-tight text-gray-700">
                                        Reduces
                                        <br>
                                        Stress
                                    </dd>
                                </div>

                                <div class="border-t-2 border-gray-100 pt-6">
                                    <dd class="text-xl font-bold tracking-tight text-gray-700">
                                        Help cope
                                        <br>
                                        with Depression
                                    </dd>
                                </div>

                                <div class="border-t-2 border-gray-100 pt-6">
                                    <dd class="text-xl font-bold tracking-tight text-gray-700">
                                        Promotes
                                        <br>
                                        positive self-talk
                                    </dd>
                                </div>
                            </dl>
                            <div class="mt-10">
                                <a target="blank"
                                    href="https://www.urmc.rochester.edu/encyclopedia/content.aspx?ContentID=4552&ContentTypeID=1#:~:text=Journaling%20helps%20control%20your%20symptoms,and%20identifying%20negative%20thoughts%20and"
                                    class="text-base font-medium text-rose-500">
                                    Learn more about benifits of Journaling &nbsp;&rarr; </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="bg-rose-600">
                <div class="pt-12 sm:pt-16 lg:pt-20">
                    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div class="text-center">
                            <h2 class="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">Simple no-tricks pricing
                            </h2>
                            <p class="mt-4 text-xl text-gray-200">If you're not satisfied, contact us within the first 14
                                days and we'll send you a full refund.</p>
                        </div>
                    </div>
                </div>
                <div class="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
                    <div class="relative">
                        <div class="absolute inset-0 h-1/2 bg-rose-600"></div>
                        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                                <div class="flex-1 bg-white px-6 py-8 lg:p-12">
                                    <h3 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">Start your membership</h3>
                                    <p class="mt-6 text-base text-gray-500">Lorem ipsum dolor sit amet consect etur
                                        adipisicing elit. Itaque amet indis perferendis blanditiis repellendus etur quidem
                                        assumenda.</p>
                                    <div class="mt-8">
                                        <div class="flex items-center">
                                            <h4
                                                class="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
                                                What's included</h4>
                                            <div class="flex-1 border-t-2 border-gray-200"></div>
                                        </div>
                                        <ul role="list"
                                            class="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                                            <li class="flex items-start lg:col-span-1">
                                                <div class="flex-shrink-0">
                                                    <!-- Heroicon name: solid/check-circle -->
                                                    <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd" />
                                                    </svg>
                                                </div>
                                                <p class="ml-3 text-sm text-gray-700">Unlimited uploads</p>
                                            </li>

                                            <li class="flex items-start lg:col-span-1">
                                                <div class="flex-shrink-0">
                                                    <!-- Heroicon name: solid/check-circle -->
                                                    <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                            clip-rule="evenodd" />
                                                    </svg>8







                                                    
                                                </div>
                                                <p class="ml-3 text-sm text-gray-700">Encryption to protect your privacy</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                                    <p class="text-lg leading-6 font-medium text-gray-900">per month</p>
                                    <div
                                        class="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                                        <span> ₹59 </span>
                                        <span class="ml-3 text-xl font-medium text-gray-500"> INR </span>
                                    </div>
                                    <p class="mt-4 text-sm">
                                        <a href="#" class="font-medium text-gray-500 underline"> Learn about our membership
                                            policy </a>
                                    </p>
                                    <div class="mt-6">
                                        <div class="rounded-md shadow">
                                            <a href="#"
                                                class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900">
                                                Subscribe
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="px-56 pt-28 relative">
                <div>
                    <img src="~@/assets/life-in-weeks.png" class="w-full rounded-2xl" alt="">
                    <div class="z-10 w-full absolute bottom-0 left-0 text-center pb-4">
                        <span class="m-auto bg-slate-50 rounded px-2 py-1 text-sm font-sans font-semibold`">
                            &copy; Sinha Ventures OPC Private Limited &bull; India
                        </span>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import { auth } from "@/main.js";
import { db } from "@/main.js";

export default {
    name: 'landing',
    data() {
        return {
            userExists: false,
        }
    },
    methods: {
        signin() {
            var provider = new auth.GoogleAuthProvider();
            auth().signInWithPopup(provider)
                .then((result) => {
                    console.log(result.user);
                    db.collection("users").doc(result.user.uid).set({
                        lastLogin: new Date().getTime(),
                        name: result.user.displayName,
                        email: result.user.email
                    }, { merge: true });
                    this.$router.push('/');
                }).catch((error) => {
                    console.log("error occured during signin", error);
                });
        }
    },
    beforeMount() {
        // console.log(auth().currentUser);
        if (auth().currentUser) this.userExists = true;
    },
}
</script>