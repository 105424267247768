<template>
    <main>
        <Navigation></Navigation>
        <div class="px-6 pb-4">
            <div class="sticky left-0 top-0 w-full z-10">
                <div class="bg-white mt-4 px-2 py-5 rounded-xl w-full shadow">
                    <div class="-ml-4 -mt-2 flex items-center justify-between">
                        <!-- Add your image here -->
                        <div class="ml-8 mt-2">
                            <h3 class="text-xl leading-6 font-black text-gray-900 text-center">
                                <span>Timeline</span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Navigation from './navigation.vue';

export default {
    name: 'Timeline',
    data() {
        return {
            // Add your data properties here
        };
    },
    methods: {
        // Add your methods here
    },
    mounted() {
        // Add your mounted hook logic here
    },
    components: { Navigation }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
